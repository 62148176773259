import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0066 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="ハイブリッドワークとは？メリットやデメリット、成功させるポイントについて解説！" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2024.08.19</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        多様な働き方が尊重される時代が到来しており、法律に明記のあるフレックスタイム制や裁量労働制等だけでなく、法律に明記はないものの、徐々に採用されつつある「ハイブリッドワーク」にも注目が集まっています。今回はハイブリッドワークのメリットデメリットの他に成功させるポイントについても解説します。{' '}
      </p>
      <img src="/images/column/details/c0066.jpg" alt="キンクラコラム画像66" />
      <h1 id="toc1">ハイブリッドワークとは</h1>
      <p>
        端的には通常のオフィスへの出社だけでなく、
        <span>働く場所を柔軟に変えられる制度</span>
        のことです。具体的に想定される就労先としてはサテライトオフィスや自宅等が挙げられますが、コロナ禍を契機に一般化したテレワークを継続する企業も少なくない中、ハイブリッドワークは求人面でも注目を集めています。
      </p>

      <h1 id="toc2">ハイブリッドワークのメリット</h1>
      <h2 id="toc2-1">(1)生産性の向上</h2>
      <p>
        オフィスと自宅の距離によっては、通勤という行為自体が相当な時間と労力を要することは珍しくありません。路線や時間帯によっては毎日が満員電車ということも考えられます。その場合、出社した時点で既に相当な体力の消耗があると窺われます。そこで、通勤の緩和（例えば通常のオフィスまでではなく、中間地点に設けられたサテライトオフィスや自宅）があれば、
        <span>体力を温存でき、通常業務により励むことができる</span>でしょう。
      </p>
      <h2 id="toc2-2">(2)ハラスメントの防止</h2>
      <p>
        もちろんリモートワークにおけるハラスメントも存在しますが、一般的にはハラスメントは対面の方が多いと言えます。ハイブリットワークになることでハラスメント行為者との接触機会が減少し、被害に遭う機会を減らすことができます。
      </p>
      <h2 id="toc2-3">(3)人件費の節約</h2>
      <p>
        出社の場合は
        <span>
          通勤定期券代を支給するといったルールを設けていた場合、ハイブリッドワークになったことで、出社回数に応じて通勤手当を支給する形に変更する
        </span>
        ことが想定されます。通勤手当の支給趣旨が「実費補填」であった場合、通勤手当で「利益」が出ること自体がおかしな話ですので、実態にあった形に規定を整備するのは自然な流れです。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc3">ハイブリッドワークのデメリット</h1>
      <h2 id="toc3-1">(1)労務管理が煩雑になる</h2>
      <p>
        出社だけでなく、リモートワークも存在する場合、前述の通勤手当の支給方法が変更になることへの対応や、
        <span>対面での労務提供と比べて労働時間管理が煩雑になる</span>
        ことは明らかです。特にチームで業務を進めていく場合、1人連絡がつきづらいメンバーがいることで、業務全体が滞るということも想定されますので、デメリットは労務管理の煩雑さだけに留まりません。よって、
        <span>
          一定の研修や共通認識を醸成させた後にハイブリッドワークを導入することが肝要
        </span>
        です。
      </p>
      <h2 id="toc3-2">(2)＋アルファの人件費がかかる</h2>
      <p>
        メリットの(3)とは逆の視点ですが、例えば通常のオフィスとは別にサテライトオフィスを設ける場合は、その施設の維持費が発生することになります。通常のオフィスでの就労の他にテレワークに付随する機器等を一定額まで会社負担とする場合は、その費用も加算されますので、通常オフィスの維持費用だけでなく、＋アルファの費用の費用も加算されるということです。ハイブリッドワークとは、働く場所を柔軟に変えることですので、多くの場合、複数の就労場所が同時に存在するということです。すなわち、複数の就労先が同時に存在することになりますので、その就労先にかかる維持費が問題となります。
      </p>
      <h2 id="toc3-3">(3)人事考課が難しくなる</h2>
      <p>
        これまでの人事考課は、多くの場合、対面での業務実績を反映していたという企業が多いと思われます。しかし、ハイブリッドワークの場合、「テレワークのみ」ということはなく、柔軟に働く場所が変わりますので、人事考課もそれに合わせてブラッシュアップしていく必要があります。そうなると
        <span>評価のブレが起こってしまうことや評価基準が定めづらい</span>
        という声があります。働ける場所が柔軟になることは、労働者目線では大変喜ばしいことではありますが、評価基準が定まっていないということは逆に不安材料と言えます。
      </p>

      <h1 id="toc4">ハイブリッドワークを成功させるポイント</h1>
      <p>
        まずはどの程度費用をかけられるのかを決めることです。会社としても無限に費用を拠出できるはずがありませんので、持続的な制度とするには、
        <span>予めかけられる費用を決めておくこと</span>
        が有用です。次に評価基準や連絡体制をルール化しておくことです。デメリットの(3)で挙げたように不明瞭な人事考課は労働者にとっては、歓迎できるはずがなく、長期雇用を阻む要因にもなり得ます。また、様々な就労先があることによって、連絡がつきづらくなってしまうと、業務が進まずに顧客に損失が生じる可能性が出てくるため、本末転倒と言わざるを得ません。
      </p>

      <h1 id="toc5">最後に</h1>
      <p>
        企業にとってどのようなハイブリッドワークが最適なのかは、企業ごとに答えが違って当然です。重要な点はハイブリッドワークを推し進めるのであれば試行錯誤して会社としての最適解を見つけるという発想です。特に多種多様な職種が存在する場合はその職種の数だけ労務管理体制も煩雑となり、そのうえでハイブリッドワークを導入するとなると「手間だけ増える」という懐疑的な意見が出てきます。また、どのメリットを享受したいのか、デメリットはどの程度許容できるのかを吟味し、導入の判断をすることが有用です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0066
