import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import ColumnBtnCard from '../atoms/columnBtnCard'

const Wrapper = styled.article`
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    span {
      display: none;
    }
  }
  ${BreakPoints.large} {
    margin-bottom: 10px;
  }
`

/**
 * 関連記事カードセット
 * TODO 川上申し送り事項：記事詳細下部に表示されるコンポーネントです。
 * ここでは、表示している記事に関連した他の記事3件が表示される予定です
 * @constructor
 */
const ColumnRelationCards0066 = () => (
  <Wrapper>
    <ColumnH1 label="関連コラム" />
    <section>
      <ColumnBtnCard
        href="/column/details/C0064"
        imgId="c0064.jpg"
        label="コロナ禍で変わった労働環境。有給取得率や残業は？"
        date="2022.04.15"
      />
    </section>
  </Wrapper>
)

export default ColumnRelationCards0066
